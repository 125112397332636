import React from 'react';
import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom'; 
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "../Styling/logout.css";

const Logout = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();

  const [arg1, setArg] = React.useState("");

  const handleChangeSelect = (selectevent) => {
    setArg(selectevent.target.value);
  };
 
  const handleLogout = () => {

    // Clear local storage or session storage
    localStorage.clear();  
    localStorage.removeItem('reponseAllValues');
    localStorage.removeItem('localstroragemail');
    localStorage.removeItem('username');
    localStorage.removeItem('instance');

   // Or sessionStorage.clear() if you're using session storage
//    sessionStorage.clear();  
//    sessionStorage.removeItem('reponseAllValues');
//    sessionStorage.removeItem('localstroragemail');
//    sessionStorage.removeItem('username');

    // Logout from MSAL
    instance.logoutRedirect()
      .then(() => {
        console.log("Logged out successfully");

        // Redirect to the login page or home page
        navigate('/'); 

        // window.open("https://dev.communityportal.appstudioltts.com/", "_self");     
      })
      .catch(e => {
        console.error("Logout failed:", e);
      });
  };
  const localData = localStorage.getItem("username");

  return (
    <>
    <span className="logoutWrap">

    <span className="userName" title={localData}>{localData}</span>
    
    <span className="profile">
        <FormControl sx={{ m: 1, minWidth: 10 }}>
            <Select value={arg1} onChange={handleChangeSelect} displayEmpty>
            <MenuItem value="" className="logoutMenu">
                <a className="userLink" onClick={handleLogout}>
                  Logout
                </a>
            </MenuItem>
            </Select>
        </FormControl>
    </span>
    </span>
    </>
  );
};
 
export default Logout;