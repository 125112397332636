import "../App.css";
import ContributorPage from "./ContributorPage";
import HomePage from "./Homepage";
import Contribute from './Contribute/Contributor';
import LoginPage from "./LoginPage";
import Loading from "./Loading";
import ApiHub from "./ApiHub";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Navigate} from 'react-router-dom';
 

function Routing() {

  const localData = localStorage.getItem("username");
  console.log("localData", localData);
  
  return (
    <Router>
      <Routes >
        <Route path="/home" element={<Loading />} /> 
        <Route path="/homePage" element={localData == null ? <Navigate to="/" replace/> : <HomePage />} />
        <Route path="/contributorPage" element= {localData == null ? <Navigate to="/" replace/> : <ContributorPage />}   />
        <Route path="/contribute" element={localData == null ? <Navigate to="/" replace/> : <Contribute />}  />
        <Route path="/apihub" element={localData == null ? <Navigate to="/" replace/> : <ApiHub />}  />
        <Route path="/" element={<LoginPage />} />
      </Routes>
    </Router>
  );
}
 
export default Routing;