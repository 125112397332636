import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-grid-system";
import Header from "./Header";
import Footer from "./Footer";
import Newcards from "./Leaderboard/RankPage";
import Notification from "./Notification";
import { useMsal } from "@azure/msal-react";
import Cookies from "js-cookie";
import ChartCardPage1 from "./PieChart1.js";
import ChartCardPage2 from "./PieChart2.js";
import ChartCardPage3 from "./BarChart.js";
import "../Common.css";

const Homepage = () => {
  console.log(
    "Screen Height a& Width : ",
    window.screen.availHeight,
    window.screen.availWidth
  );
  const { instance, accounts } = useMsal();
  const [showNotification, setShowNotification] = useState(false);
  const [contributorValidate, setContributorValidate] = useState(false);
  const [addUserDetails, setAddUserDetails] = useState(false);
  const [deliveryUnit, setDeliveryUnit] = useState("");
  const [deliveryGroup, setDeliveryGroup] = useState("");

  useEffect(() => {
    const UsersApi = "https://prod.appstudioltts.com/api/auth";
    fetch(UsersApi)
      .then((response) => {
        console.log("Response : ", response);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((usersData) => {
        const UserValidate = usersData.filter(
          (i) => i.UserMailID == localStorage.getItem("usermail")
        );
        console.log("user after validation : ", UserValidate.length);
        if (UserValidate.length > 0) {
          setShowNotification(false);
          UserValidate[0].UserType.toLowerCase() != "consumer"
            ? setContributorValidate(true)
            : setContributorValidate(false);
          setAddUserDetails(false);
        } else {
          setShowNotification(true);
          setContributorValidate(false);
          setAddUserDetails(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const handleNotificationClose = () => {
    setShowNotification(false);
    localStorage.setItem("notificationShown", "true");
  };
  const [totalUsers, setTotalUsers] = useState(null)


  useEffect(() => {
    const checkAndStoreCredentials = async() => {
      const storedCredentials = Cookies.get("userCredentials");
      
      if (!storedCredentials && accounts.length > 0) {        
        const username = accounts[0].name;
        const userMailID = accounts[0].username;
        localStorage.setItem('usermail',userMailID)
        localStorage.setItem('username',username)

        try {
          const response = await fetch(`https://prod.appstudioltts.com/api/empdata/employees/${userMailID}`);
          const data = await response.json();
          localStorage.setItem("employeeData", JSON.stringify(data));
          if (Array.isArray(data) && data.length > 0) {
            deliveryUnit = data[0].DeliveryUnit;
            deliveryGroup =  data[0].DeliveryGroup;
          }
        } catch (error) {
          console.error("Error fetching employee data:", error);
        }
        console.log("Delivery Data : ", deliveryUnit,deliveryGroup)
        const apiUserData = [
          {
            UserName: username,
            UserMailID: userMailID,
            UserType: "Consumer",
            DeliveryGroup: deliveryGroup,
            DeliveryUnit: deliveryUnit,
          }
        ]

          // let TotalUsers;
          fetch("https://prod.appstudioltts.com/api/auth",{
            method : "GET",
            headers: {
              "Content-Type": "application/json",
            }
          })
          .then((response)=> setTotalUsers(response))
          .catch((error) => {
            console.error("Network error:", error);
          });

          console.log("Total users ", totalUsers)

        fetch("https://prod.appstudioltts.com/api/auth/login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(apiUserData),
        })
          .then((response) => {
            sendEmail(userMailID);

            if (response.ok) {
              console.log("User data stored successfully.");
              Cookies.set("userCredentials", "stored", { expires: 365 });
              window.location.reload();
            } else {
              console.error("Failed to store user data:", response.status);
            }
          })
          .catch((error) => {
            sendEmail(userMailID);
            console.error("Network error:", error);
          });
      }
      //AuthGuard
      // else{
    //   window.location.replace('http://localhost:3000/');
    //   window.location.replace('https://dev.communityportal.appstudioltts.com/');
    // }
      
      // else{
      //   const DEV = "http://localhost:3000/";
      //   const PROD = "https://dev.communityportal.appstudioltts.com/";

      //   var urlIs =  window.location.href;
      //   if(urlIs.includes(DEV))
      //   {
      //     window.location.replace('http://localhost:3000/')
      //   }
      //   else if(urlIs.includes(PROD))
      //   {
      //     window.location.replace('https://dev.communityportal.appstudioltts.com/')
      //   }
      // }
    };

    checkAndStoreCredentials();
  }, [accounts]);

  const sendEmail = (userMailID) => {
    fetch("https://prod.appstudioltts.com/api/logingpage-mail/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: userMailID }),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Email sent successfully.");
        } else {
          console.error("Failed to send email:", response.status);
        }
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };

  const fetchEmployeeData = async (userEmail) => {
    try {
      const response = await fetch(`https://prod.appstudioltts.com/api/empdata/employees/${userEmail}`);
      const data = await response.json();
      localStorage.setItem("employeeData", JSON.stringify(data));
      if (Array.isArray(data) && data.length > 0) {
        setDeliveryUnit(data[0].DeliveryUnit);
        setDeliveryGroup(data[0].DeliveryGroup);
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    }
  };
  const localData = localStorage.getItem("username");

  return (
    <>
     {localData == null ? window.location.href="/" : (<>
      <Header>
        <Container fluid>
          <Row>
            <Col sm={12} xs={12} md={12} lg={12} xl={12} xxl={12} xxxl={12}>
               <Header />
            </Col>
          </Row>
        </Container>
      </Header>
      
      <Container fluid className="maincommunity">
        <Row className="LeaderBoardFirst">
          <Col sm={12} xs={12} md={12} lg={8} xl={9} xxl={9} xxxl={9} style={{ paddingRight: "0px" }}>
            <Row>
              <Col sm={12} xs={12} md={12} lg={6} xl={6} xxl={6} xxxl={6} style={{ paddingLeft: "9px", paddingRight: "0px" }}>
                <ChartCardPage1 />
              </Col>
              <Col sm={12} xs={12} md={12} lg={6} xl={6} xxl={6} xxxl={6}>
                <ChartCardPage2 />
              </Col>
            </Row>
            <Row>
              <Col className="barChartCarForPadding" sm={12} xs={12} md={12} lg={12} xl={12} xxl={12} xxxl={12}>
                <ChartCardPage3/>
              </Col>
            </Row>
          </Col>

          <Col sm={12} xs={12} md={12} lg={4} xl={3} xxl={3} xxxl={3} style={{ paddingRight: "9px" }} className="NewcardsOuter">
            <Newcards />
          </Col>
        </Row>
      </Container>
      
      <div className="FooterClass">
        <Container fluid className="footerSize">
          <Row className="AppBar AppFooter">
            <Col sm={12} xs={12} md={12} lg={12} xl={12} xxl={12} xxxl={12}>
              <span className="spanFooterLT">
                © L&T Technology Services : All Rights Reserved.
              </span>
              <span className="Version">App Studio v1.0</span>
              <span>
                <Footer />
              </span>
            </Col>
          </Row>
        </Container>
      </div>
      </>)}
      {showNotification && <Notification onClose={handleNotificationClose} />}
    </>
  );
};

export default Homepage;

