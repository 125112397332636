import React, { useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { TableBody, TableCell, TableRow, Table, TableHead, TableContainer, Paper} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@mui/system";
import "../../../Styling/leaderboard.css";

const useStyles = makeStyles({
  table: {
    width: "573px",
    marginBottom:"0px"
  },
  container: {
    width: 1000,
  }
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function CustomizedDialogs({ details, title, onClick }) {
  const [open, setOpen] = useState(true);
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenu, setOpenMenu] = React.useState(false);

  const handleFilter = (rows, column, value) => {
    if (value === null) {
      return rows;
    }
    return rows.filter((row) => row[column] === value);
  };

  const getUniqueValues = (arr, key) => {
    return [...new Set(arr.map((item) => item[key]))];
  };

  const handleClick = (event, column) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
    setSelectedColumn(column);
    if (selectedColumn !== null) {
      setSelectedValue(details[0][column]);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={onClick}
        aria-labelledby="customized-dialog-title"
        open={open}>
        <DialogTitle sx={{width:"1016px", m: 0, p: 2 }} id="customized-dialog-title" className="dialog-title">
          {title}
        </DialogTitle>
        <IconButton aria-label="close" onClick={onClick} className="mac-close"
          sx={{ position: "absolute", right: "8px", top: "8px", color: "white" }}>
          <CloseIcon />
        </IconButton>
        <DialogContent className="dialog-box">
          <Typography>
            <TableContainer component={Paper} className={classes.container}>
              <Table className={classes.table} stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Sl No</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell style={{padding:"11px 0px"}}>
                      User Type
                      <IconButton onClick={(e) => handleClick(e, "type")} style={{padding:"8px 0px"}}>
                        {selectedColumn === "type" ? (
                          <KeyboardArrowUpIcon fontSize="small" />
                        ) : (
                          <KeyboardArrowDownIcon fontSize="small" />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      Business Unit
                      <IconButton onClick={(e) => handleClick(e, "BU")}>
                        {selectedColumn === "BU" ? (
                          <KeyboardArrowUpIcon fontSize="small" />
                        ) : (
                          <KeyboardArrowDownIcon fontSize="small" />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      Delivery Unit
                      <IconButton onClick={(e) => handleClick(e, "DU")}>
                        {selectedColumn === "DU" ? (
                          <KeyboardArrowUpIcon fontSize="small" />
                        ) : (
                          <KeyboardArrowDownIcon fontSize="small" />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {handleFilter(details, selectedColumn, selectedValue).map((i, index) => (
                    <TableRow key={i.id} sx={{p : 1 }}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell className="text-break">{i.name}</TableCell>
                      <TableCell  className="text-break">{i.id}</TableCell>
                      <TableCell sx={{pr : 5}}>{i.type}</TableCell>
                      <TableCell>{i.BU}</TableCell>
                      <TableCell>{i.DU}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Typography>
        </DialogContent>
        <DialogActions className="okButton">
              <Button sx={{position:"fixed",padding:"8px 16px 6px 16px"}} onClick={handleCloseDialog} variant="contained">
                Ok
              </Button>
            </DialogActions>
      </BootstrapDialog>
      {openMenu && (
        <Menu
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleClose}
          onClick={handleClose}
        >
          {getUniqueValues(details, selectedColumn).map((option) => (
            <MenuItem
              key={option}
              selected={selectedValue === option}
              onClick={() => {
                setSelectedValue(option);
                setOpenMenu(false);
              }}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      )}
    </React.Fragment>
  );
}