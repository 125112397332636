import React, { useEffect, useState } from "react";
import LeaderboardUsericon from "../../../Images/LeaderboardUserIcon.png";
import { useMsal } from "@azure/msal-react";
import "../../../Styling/leaderboard.css";

function UserRank() {
    const [tableDataContributors, setTableDataContributors] = useState([]);
    const { instance } = useMsal();
    const loggedUser = instance.getAllAccounts()[0]?.username;
    const fetchData = () => {
        fetch("https://prod.appstudioltts.com/api/rank/fetch-rank-data")
            .then((response) => response.json())
            .then((data) => {
                setTableDataContributors(data);
            })
            .catch((error) => {
                console.error("Error fetching contributors data:", error);
            });
    }

    useEffect(()=>{
        fetchData();
    },[])
    return (
        <>
            <div className="userrank">
                <img src={LeaderboardUsericon} width="39px" height="39px" className="usericon" />
                <span className="usertext">
                    <span className="bold-txt-t">You</span>
                    <span>Latest Rank</span>
                </span>

                {tableDataContributors.filter(item => item.email == loggedUser).map((user) => {
                    return (
                        <span className="rank" >{user.rank}</span>
                    )
                }
                )}
            </div>
        </>
    )
}

export default UserRank;