import React, { useState } from "react";
import LeaderboardUsericon from "../../../Images/LeaderboardUserIcon.png";
import RankTag from "../RankTag";
import { Divider } from "@material-ui/core";
import "../../../Common.css";

function UserRank({ details, val }) {
    const sortedArray = details.sort((a, b) => a.rank > b.rank);

    const [name, setName] = useState('')
    return (
        <>
            {sortedArray.slice(0, 3).map((item) => {
                const name = item.senderName.toLowerCase()
                const userName = name
                    .split(' ')
                    .map(word => {
                        return word.charAt(0).toUpperCase() + word.slice(1);
                    })
                    .join(' ');
                return (
                    <div className="userdetails">
                        <img src={LeaderboardUsericon} width="39px" height="39px" className="usericon" />
                        <span className="usertext">
                            <span className="u-name-2">{userName}</span>
                            <span className="Contributed-comp-1">Contributed Components</span>
                        </span>   
                        <span className="rank-tag-1"><RankTag rank={item.rank} /></span>
                        <span> <Divider /></span>
                    </div>
                )
            })}
        </>
    )
}

export default UserRank;