import React, { useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { TableBody, TableCell, Table, TableHead, TableContainer, Paper } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { makeStyles } from "@material-ui/core/styles";
import "../../../Styling/leaderboard.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const useStyles = makeStyles({
  table: {
    width:"573px",
    marginBottom:"0px"
  },
  container: {
   // maxHeight: 420,
  },
});

export default function CustomizedDialogs({ details,onClick }) {
  const [open, setOpen] = useState(true);
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const options = ['Download Certificate', 'Place Holder', 'Place Holder'];

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = value => () => {
    setSelectedOption(value);
    setIsOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false)
  };
  
  return (
    <React.Fragment>
      <BootstrapDialog onClose={onClick} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className="dialog-title">
          Total Components
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClick}
          sx={{ position: "absolute", right: "8px", top: "8px", color: "white" }}>
          <CloseIcon />
        </IconButton>
        <DialogContent className='dialog-box userTable-dialog-box'>
          <Typography>
           <TableContainer component={Paper} className={classes.container}>
            <Table stickyHeader className={classes.table} aria-label="sticky table">
              <TableHead>
                <TableCell>ID</TableCell>
                <TableCell>Component</TableCell>
                <TableCell>Technology</TableCell>
                <TableCell>Contributor</TableCell>
              </TableHead>
              {details.sort((item)=>item.ID).map((i,index) => (
                <TableBody className="text-break">
                  <>
                    <TableCell>{index+1}</TableCell>
                    <TableCell>{i.ComponentName}</TableCell>
                    <TableCell>{i.Technology}</TableCell>
                    <TableCell>{i.SenderName}</TableCell>
                  </>
                </TableBody>
              ))}
            </Table>
            </TableContainer>
          </Typography>
        </DialogContent>
        <DialogActions className="okButton">
          <Button sx={{ position:"fixed", padding:"8px 16px 6px 16px"}} onClick={onClick} variant="contained">
            Ok
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}