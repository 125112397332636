import React, { useEffect, useState } from "react";
import LeaderboardUsericon from "../../../Images/LeaderboardUserIcon.png";
import {Divider} from "@material-ui/core";
import "../../../Common.css";

function UserRank({details}) {

    const sortedArray = details.sort((a,b)=>a.rank>b.rank);

    return (
        <>
        { sortedArray.slice(0,4).map((item)=>{
             const name = item.name.toLowerCase()
             const userName = name
             .split(' ')
             .map(word => {
               return word.charAt(0).toUpperCase() + word.slice(1);
             })
             .join(' ');
            return(
                <div className='userdetails'>
                <img src={LeaderboardUsericon} width="39px" height="39px" className="usericon" />
                <span className='usertext'>
                    <span className="u-name-1">{userName}</span>
                    <span className="consumed-comp-1">Consumed Components</span>
                </span>
               <div> <Divider/></div>
            </div>
            )
        })}
        </>
    )
}

export default UserRank;