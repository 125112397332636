import logo from "../../../Images/LttsLogo.png";
import appstudiologo from "../../../Images/Appstudiopng.png";
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import "../../../Styling/certificate.css";

const ComponentToPrint = React.forwardRef((props, ref) => {
    const {name,rank} = props
      return(
        <div ref={ref}>
        {/* Your Certificate Data */}
       <div class="page">
      <div class="subpage">
        <img src={logo} height="80px" className="certificate-logo-1"/>
        <div className="heading">Certification of Completion</div>
        <p className="heading-1">This is to Certify that </p>
        <p className="heading top-space">{name}</p>
        <p className="rank-txt">Has Acheived Rank of</p>
        <p className="rank-number-2">{rank}</p>
        <p className="contributor-name">as a Contributor to </p>
        <img src={appstudiologo} className="appstudiologo"/>
        <p className="project-info">
            App Studio is an in-house solution accelerator for LTTS developers. Its a web-based frontend library that allows users to consume and contribute reusable components to and from their projects.</p>
      </div>
    </div>
    </div>
      )
      });

const DownloadCertificate = ({list}) => {
    const componentRef = useRef();
    return (
        <div>
            <ReactToPrint
                trigger={() => <a className="download-certificate">Download Certificate</a>}
                content={() => componentRef.current} />
            <div style={{display: 'none'}}>
                <ComponentToPrint ref={componentRef} name={list[0].senderName} rank={list[0].rank}/>
            </div>
        </div>
    );
}

export default DownloadCertificate;