import React from "react";
import LoginIcon from "../Images/MicrosoftTeams-image (42).png";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Button from "@mui/material/Button";
import "../Common.css";

const LoginPage = () => {
  const { instance } = useMsal();

  const handleSignIn = () => {
    instance
      .loginPopup({
        ...loginRequest,
        prompt: "create", // You can modify the prompt as needed
      })
      .then(() => {
        window.location.href = '/home'; // Redirects to the home page
      })
      .catch((error) => console.log(error)
      ); // Logs any errors
  };

  return (
    <div className="image-container">
      {/* Left Side: Image */}
      <div>
        <img src={LoginIcon} className="login-img-i" alt="Login Icon" />
      </div>

      {/* Right Side: Community Text and Login Button */}
      <div className="text-and-button">
        <div>Welcome to the App Studio Community</div>

        <Button className="LoginPageButton" color="inherit" onClick={handleSignIn}>
          Login With Outlook Credentials
        </Button>
      </div>
    </div>
  );
};

export default LoginPage;
