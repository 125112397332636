import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import "../../Styling/leaderboard.css";
import Tabview from "./Tab";
import componentsIcon from "../../Images/Components.png";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import { FaUsers } from "react-icons/fa6";
import DialogConusmer from "./Consumer/DialogConsumer";
import ComponentList from "./Table/UserTable";

const useStyles = makeStyles({
    boardcard: {
        height: '360px',
        position: 'relative',
        bottom: 6,
        margin: '7px 0px 0px 0px'
    },
    cards: {
        position: 'relative',
        margin: '4px 7px 5px 7px',
        cursor:'pointer'
    },
    count:{
    fontSize: 'xx-large'
    }
});

const Leaderboard = () => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [componentsList, setComponentsList] = useState([]);
    const [consumersList, setConsumersList] = useState([]);
    const [userClick, setUserClick] = useState(false);
    const [compoClick, setCompoClick] = useState(false);

    const fetchData = () => {
        fetch("https://prod.appstudioltts.com/api/auth")
            .then((response) => response.json())
            .then((data) => {
                setConsumersList(
                    data.map((item) => ({
                        id: item.UserMailID,
                        name: item.UserName,
                        type: item.UserType,
                        BU: item.DeliveryGroup,
                        DU: item.DeliveryUnit,
                    }))
                );
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });

        fetch("https://prod.appstudioltts.com/api/linkstateupdate/published-components")
            .then((response) => response.json())
            .then((data) => {
                setComponentsList(data);
            })
            .catch((error) => {
                console.error("Error fetching component details:", error);
            });
    };

    function handleUser (){
        setUserClick(!userClick);
    }

    function handleCompo () {
        setCompoClick(!compoClick)
    }

    useEffect(() => {
        fetchData();
    }, []);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
        <div className={classes.container} >
            <Card className={classes.boardcard}>
                <CardContent>
                    <Typography sx={{ fontSize: "14px", fontWeight:"bolder" }}>Leaderboard</Typography>
                    <Typography><Tabview /></Typography>
                </CardContent>
            </Card>
            <Card  onClick={handleUser} className="cardheight">
                <CardContent display="flex" alignItems="end">
                    <Box display="flex"  alignItems="center" justifyContent="center" textAlign="center" fontSize="18px" fontWeight="bolder" ml={1} marginRight="22px">
                        <div className={classes.count}>{consumersList.length}</div>
                    </Box>
                    <div className="totalUsers">
                        <p className="ttl-users">Total Users</p>
                        <FaUsers className="fa-user-hw"/>
                    </div>
                </CardContent>
            </Card>
            <Card onClick={handleCompo} className="cardheight">
                <CardContent>
                    <Box display="flex" alignItems="center" justifyContent="center" textAlign="center" fontSize="18px" fontWeight="bolder" ml={1} marginRight="22px">
                        <div className={classes.count}>{componentsList.length}</div>
                    </Box>
                    <div className="totalUsers">
                    <p className="ttl-comps">Total Components</p>
                        <img src={componentsIcon} className="totalComponentsIcon" alt="Contribute Icon"/>
                    </div>
                </CardContent>
            </Card>
        </div>
        {userClick?<><DialogConusmer onClick={handleUser} details={consumersList} title="Total Users" style={{margin:"0 auto"}}/></>:""}
        {compoClick?<><ComponentList onClick={handleCompo} details={componentsList} style={{margin:"0 auto"}} /></>:""}
        </>
    )
}
export default Leaderboard;