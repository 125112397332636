import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AppLogo2 from "../Icons/appLogo.png";
import Icon from "../Images/MicrosoftTeams-image (43).png";
import Icon2 from "../Images/gotoPlayground.png";
import Icon3 from "../Images/Contribute.png";
import Icon4 from "../Images/BeaContributor.png";
import { useMsal } from "@azure/msal-react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import SendFeedbackPage from "./SendFeedbackPage";
import "../Common.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tooltip from "@mui/material/Tooltip";
import { Container, Row, Col } from "react-grid-system";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Logout from "./Logout";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color:"#fff",
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

//Tab's function
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Header(props) {
  const { instance } = useMsal();
  const [email, setEmail] = useState("");
  const [canContribute, setCanContribute] = useState(true);
  const [inProgress, setInProgress] = useState(false);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();


  //------ Logout Session After 2hrs ---------
  useEffect(() =>{
    clearAppSession();
  }, [])

  const clearAppSession = () => {
    const clearTime = setTimeout( () =>{
      localStorage.clear();  
    },7200000 );

    return() => clearTimeout(clearTime);
  }
//--------------- End ---------------------
  useEffect(() => {
    if (location.pathname === "/home") {
      setValue(0);
    }
    if (location.pathname === "/apihub") {
      setValue(1);
    }
  }, [location]);

  const handleChange = (event, newValue) => {
    newValue === 0 ? navigate("/home") : navigate("/apihub");
    setValue(newValue);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    localStorage.getItem("usermail") != null ||
    localStorage.getItem("username") !== null
      ? setCanContribute(true)
      : setCanContribute(false);
  }, []);

  useEffect(() => {
    const userEmail = instance.getAllAccounts()[0]?.username;
    setEmail(userEmail);

    if (userEmail) {
      fetch("https://prod.appstudioltts.com/api/requestpage/acceptedrequest")
        .then((response) => response.json())
        .then((acceptedRequestData) => {
          const isPresentInAcceptedRequest = acceptedRequestData.some(
            (item) => item.Email === userEmail
          );

          if (isPresentInAcceptedRequest) {
            setCanContribute(true);
          } else {
            fetch("https://prod.appstudioltts.com/api/skills/skillsview")
              .then((response) => response.json())
              .then((skillsViewData) => {
                const isPresentInSkillsView = skillsViewData.some(
                  (item) => item.Email === userEmail
                );

                if (isPresentInSkillsView) {
                  setInProgress(true);
                } else {
                  setCanContribute(false);
                  setInProgress(false);
                }
              })
              .catch((error) => {
                console.error("Error fetching skillsview API:", error);
                setCanContribute(false);
                setInProgress(false);
              });
          }
        })
        .catch((error) => {
          console.error("Error fetching acceptedrequest API:", error);
          setCanContribute(false);
          setInProgress(false);
        });
    }
  }, []);

  const handleInProgressClick = () => {
    // Show an error notification
    toast.error("Your Approval request is in progress", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  console.log("UserLogged in : ", props);

  const handlePlaygroundClick = () => {
    if (location.pathname === "/home") {
      window.open("https://playground.appstudioltts.com/#/", "blank");
    }
    if (location.pathname === "/apihub") {
      window.open("https://devxidp.com/", "blank");
    }
  };

  return (
    <>
      <AppBar className="HeaderCss">
           <Row className="headerContainer">
            <Col sm={6} xs={6} md={6} lg={6} xl={6} xxl={8} xxxl={8} className="leftHeader remove-spc">
              <img className="AppLogo" src={AppLogo2} alt="App Logo" />
              <Tabs
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{style: { backgroundColor: "#FFFFFF", padding:"0px"},}}
                aria-label="basic tabs example"
                className="commAndApiDiv">
                <Tooltip title="App Studio">
                  <Tab
                    label={
                      <Typography style={{ padding:"0px" }} variant="h6" className="textApp">
                        App Studio
                      </Typography>
                    }
                    {...a11yProps(0)}
                  />
                </Tooltip>
                <Tooltip title="API Hub">
                  <Tab
                    label={
                      <Typography variant="h6" className="textApp">
                        API Hub
                      </Typography>
                    }
                    {...a11yProps(1)}
                  />
                </Tooltip>
              </Tabs>
            </Col>

            <Col sm={6} xs={6} md={6} lg={6} xl={6} xxl={4} xxxl={4} className="remove-spc marg-t rightHeader">
              <span className="buttons-span-width display-1-line">
                {canContribute && !inProgress ? (
                  <NavLink to="/contribute">
                    <Tooltip title="Contribute">
                      <Button variant="contained" className="headerContributeBtn hide-on-small-screens">
                        <img
                          // className="AppLogo3"
                          src={Icon3}
                          className="newHeaderimageButton"
                          alt="Contribute Icon"
                        />
                        <span className="hide-on-small-screens">
                          Contribute
                        </span>
                      </Button>
                    </Tooltip>
                  </NavLink>
                ) : inProgress ? (
                  <Button
                    variant="contained"
                    className="headerAppPendBtn hide-on-small-screens"
                    onClick={handleInProgressClick}>
                    <span className="hide-on-small-screens">
                      Approval pending
                    </span>
                  </Button>
                ) : (
                  <NavLink to="/contributorPage">
                    <Tooltip title="Be a Contributor">
                      <Button
                        variant="contained"
                        className="headerBeacontBtn hide-on-small-screens">
                        <img
                          src={Icon4}
                          className="newHeaderimageButton"
                          alt="Contribute Icon"
                        />
                        <span className="hide-on-small-screens">
                          Be a Contributor
                        </span>
                      </Button>
                    </Tooltip>
                  </NavLink>
                )}

                {}
                <Tooltip title="Go to Playground">
                  <Button
                    variant="contained"
                    className="headerGotoplBtn hide-on-small-screens"
                    onClick={handlePlaygroundClick}>
                    <img src={Icon2} className="headerGoToplIcon" alt="Playground Icon"/>

                    <span className="hide-on-small-screens">
                      Go To Playground
                    </span>
                  </Button>
                </Tooltip>

                <Tooltip title="Send Feedback">
                  <Button onClick={() => setOpen(true)} className="headerSendFdBtn hide-on-small-screens">
                    <span>
                      <img className="AppLogo2" src={Icon} alt="Icon" />
                    </span>
                    <span className="hide-on-small-screens">
                      {" "}
                      Send Feedback
                    </span>
                  </Button>
                </Tooltip>

                {/* ----- Logout ----- */}
                <span>
                  <Logout />
                </span>
              </span>
            </Col>
           </Row>
      </AppBar>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} className="popup-heading hide-on-small-screens">
          Send Feedback
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography className="p-font" gutterBottom>
            <div>
              <SendFeedbackPage />
            </div>
          </Typography>
        </DialogContent>
      </BootstrapDialog>
      <ToastContainer />
    </>
  );
}
