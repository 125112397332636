import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { TableBody, TableCell, Table, TableHead, TableRow, TableContainer, Paper } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LeaderboardUserIcon from "../../../Images/LeaderboardUserIcon.png";
import icon1L from "../../../Images/1icon.png";
import icon2L from "../../../Images/2icon.png";
import icon3L from "../../../Images/3icon.png";
import iconwithstar from "../../../Images/iconwithstar.png";
import dropdowniconL from "../../../Images/dropdownicon.png";
import Certificate from "../Certificate/Certificate";
import "../../../Styling/certificate.css";
import { useMsal } from "@azure/msal-react";
import "../../../Styling/leaderboard.css";
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from "@mui/material/Tooltip";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const useStyles = makeStyles({
  table: {
    minWidth: 450,
    marginBottom: '5px'
  },
  tbody: {
    fontSize: '13px'
  },

  Mytooltip: {
    borderRadius: '60px',
    paddingTop: '9px',
    paddingBottom:'9px',
    textWrap: 'wrap',
    fontSize: '1rem',
    cursor:'pointer'
  },
});

export default function CustomizedDialogs({ details,onClick }) {
  const { instance } = useMsal();
  const [open, setOpen] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const options = ['Download Certificate', 'Place Holder', 'Place Holder'];
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [openDialog71_1, setOpenDialog71_1] = useState(false);
  const toggling = () => setIsOpen(!isOpen);
  const classes = useStyles();
  let componentRef = useRef();
  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  
  const handleCloseDialog71_1 = () => {
    setOpenDialog71_1(false);
  };
  const onOptionClicked = value => () => {
    setSelectedOption(value);
    setIsOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false)
  };

  const sortedArray = details.sort((a, b) => a.rank > b.rank);
  let userRank;
  const loggedUser = instance.getAllAccounts()[0]?.username;
  console.log("Logged in User is :",loggedUser)
  sortedArray.filter(item => item.email == loggedUser).map((user) => userRank = user.rank)
  console.log("Userrank is : ", sortedArray)

  //Custom Tooltip
  const CustomTooltip =({index})=>{
    return(
      <div>
          <p>User Email : { sortedArray[index].email}</p>  
         
          <p>Components Count : {sortedArray[index].publishedCount}</p>
      </div>
    )
  }

  return (
    <React.Fragment>
      <BootstrapDialog onClose={onClick} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className="dialog-title">
          Leaderboard
        </DialogTitle>
        <IconButton aria-label="close" onClick={onClick} sx={{ position: 'absolute', right: "8px", top: "8px", color: 'white', }}>
          <CloseIcon />
        </IconButton>
        <DialogContent className="dialogContent">
          <img src={LeaderboardUserIcon} alt="Leaderboard User Icon" className="user-icon"/>
          <p className="yourrank">
            <p className="yourRankName"> Your Rank</p>
            <span className="rank-no1">{userRank}</span>
            <img className="dropdownIcon" src={dropdowniconL} onClick={handleDropdownClick} onKeyDown={handleDropdownClick} />
            </p>
          {isDropdownOpen && (
            <ul className="contribute-dialog-ul">
              <li className="contribute-dialog-li"
                onMouseOver={(e) => (e.target.style.backgroundColor = "#add8e6")}
                onFocus={(e) => (e.target.style.backgroundColor = "#add8e6")}
                onMouseOut={(e) => (e.target.style.backgroundColor = "transparent")}
                onBlur={(e) => (e.target.style.backgroundColor = "transparent")}>
                <Certificate list={sortedArray.filter(item => item.email == loggedUser)}/>
              </li>
              <li className="contribute-dialog-li-1"
                onMouseOver={(e) => (e.target.style.backgroundColor = "#add8e6")}
                onFocus={(e) => (e.target.style.backgroundColor = "#add8e6")}
                onMouseOut={(e) => (e.target.style.backgroundColor = "transparent")}
                onBlur={(e) => (e.target.style.backgroundColor = "transparent")}
                onClick={() => console.log("More clicked")}
                onKeyDown={() => console.log("More clicked")}>
                More
              </li>
            </ul>
          )}
          <div className="rectangle-container">
          <Tooltip title={<CustomTooltip index={1} />} classes={{ tooltip: classes.Mytooltip }} className="hand-tool-i">
            <div className="blue-rectangle2">
              
              <img src={iconwithstar} className="user-icon-with-star-1" alt="iconwithstar" />
              
              <img src={icon2L} className="user-icon-with-star-2" alt="LeaderboardIcon" />
             
              {sortedArray[1] && (
                sortedArray[1].senderName.length<=18 ? 
                <p className="sender-name-1">
                  {sortedArray[1].senderName}
                </p> :
                <p className="sender-name-2">
                {sortedArray[1].senderName}
              </p>
              )}
            </div>
            </Tooltip>

            <Tooltip title={<CustomTooltip index={0}/>} classes={{ tooltip: classes.Mytooltip }}  className="hand-tool-i">
            <div className="blue-rectangle1">

              <img src={iconwithstar} className="icon-with-star-3" alt="iconwithstar" />
              
              <img src={icon1L} className="icon-with-star-4" alt="Leaderboar Icon" />
              
              {sortedArray[0] && (
                sortedArray[0].senderName.length<=18 ? 
                <p className="sender-name-5">
                  {sortedArray[0].senderName}
                </p> :
                <p className="sender-name-6">
                {sortedArray[0].senderName}
              </p>
              )}
            </div>
            </Tooltip>

            <Tooltip title={<CustomTooltip index={2}/>} classes={{ tooltip: classes.Mytooltip }} className="hand-tool-i">
            <div className="blue-rectangle3">
              <img src={iconwithstar} className="icon-with-star-1" alt="iconwithstar" />

              <img src={icon3L} className="icon-with-star-2" alt="Leader Icon" />

              {sortedArray[2] && (
               sortedArray[2].senderName.length<=18 ? 
               <p className="sender-name-3">
                 {sortedArray[2].senderName}
               </p> :
               <p className="sender-name-4">
               {sortedArray[2].senderName}
             </p>
              )}
            </div>
            </Tooltip>
          </div>

          <TableContainer component={Paper} className={classes.container} id="l-table-container">
            <Table stickyHeader className={classes.table} aria-label="sticky table">
              <TableHead style={{ display: "block", maxHeight: 185, overflow: "auto"}}>
                <TableCell style={{width:"12%"}}>Rank</TableCell>
                <TableCell style={{width:"22%"}}>Name</TableCell>
                <TableCell style={{width:"25%"}}>Email</TableCell>
                <TableCell style={{width:"20%"}}>Components Count</TableCell>
              </TableHead>
              <TableBody id="l-table" className={classes.tbody} style={{ display: "block", maxHeight: 185, overflow: "auto"}}>
                {sortedArray.slice(3,).map((i) => (
                  <TableRow>
                    <TableCell style={{width:"12%"}}>{i.rank}</TableCell>
                    <TableCell style={{width:"20%",paddingLeft:"20px !important"}}>{i.senderName}</TableCell>
                    <TableCell style={{width:"27%",paddingLeft:"12px !important"}} className="text-break">{i.email}</TableCell>
                    <TableCell style={{width:"20%",paddingLeft:"18px !important"}}> {i.publishedCount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions className="okButton">
          <Button sx={{position:"fixed",padding:"8px 16px 6px 16px"}} onClick={onClick} variant="contained">
            Ok
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}