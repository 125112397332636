import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import UserRank from "./Table/UserRank";
import ContributorDetails from "./Contributor/ContributorDetails";
import ConsumerDetails from "./Consumer/ConsumerDetails";
import DialogConusmer from "./Consumer/DialogConsumer";
import DialogContribute from "./Contributor/DialogContributor";
import { useMsal } from "@azure/msal-react";
import "../../Styling/leaderboard.css";

const useStyles = makeStyles({
    tabroot: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: '12px'
    }
});
// Tab Views a11yProps
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const TabView = () => {
    const classes = useStyles()
    // Values Declarations 
    const [value, setValue] = React.useState(0);
    const [consumersList, setConsumersList] = useState([]);
    const [contributorsList, setContributorsList] = useState([]);
    const [openDialog71_1, setOpenDialog71_1] = useState(false);
    const [tableData71_2, setTableData71_2] = useState([]);
    const [tableData50, setTableData50] = useState([]);
    const [RankingContributors, setRankingContributors] = useState([]);
    const [consumeDialog,setConsumeDialog] = useState(false)
    const [contributeDialog,setContributeDialog] = useState(false)
    const { instance } = useMsal();
    const loggedUser = instance.getAllAccounts()[0]?.username;
    const fetchData = () => {
        fetch("https://prod.appstudioltts.com/api/auth")
            .then((response) => response.json())
            .then((data) => {
                setConsumersList(
                    data.map((item) => ({
                        id: item.UserMailID,
                        name: item.UserName,
                        type: item.UserType,
                        BU: item.DeliveryGroup,
                        DU: item.DeliveryUnit,
                    }))
                );
                setTableData71_2(
                    data.map((item) => ({
                        id: item.UserMailID,
                        name: item.UserName,
                        type: item.UserType,
                        BU: item.DeliveryGroup,
                        DU: item.DeliveryUnit,
                    }))
                );
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });

        fetch("https://prod.appstudioltts.com/api/requestpage/acceptedrequest")
            .then((response) => response.json())
            .then((data) => {
                setContributorsList(
                    data.map((item) => ({
                        id: item.SenderName,
                        name: item.Email,
                    }))
                );
            })
            .catch((error) => {
                console.error("Error fetching accepted requests:", error);
            });

        fetch("https://prod.appstudioltts.com/api/componentdetails/fetch-details")
            .then((response) => response.json())
            .then((data) => {
                setTableData50(data);
            })
            .catch((error) => {
                console.error("Error fetching component details:", error);
            });

        fetch("https://prod.appstudioltts.com/api/rank/fetch-rank-data")
            .then((response) => response.json())
            .then((data) => {
                setRankingContributors(data);
            })
            .catch((error) => {
                console.error("Error fetching contributors data:", error);
            });
    };

    useEffect(() => {
        fetchData();      
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // Dialog for Consumer and Contributors
    function handleConsumerList(){
        setConsumeDialog(!consumeDialog)
    }

    function handleContributorsList(){
        setContributeDialog(!contributeDialog)
    }

    const handleDialog71_1Click = () => {
        setOpenDialog71_1(!openDialog71_1);
    };

    return (
        <>
            <Box sx={{ width: "100%", paddingRight: "10px" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab className="w-50" label={
                            <Box display="flex"  alignItems="center" fontSize="12.5px" fontWeight="bolder">
                                <Box>Consumers ({consumersList.length})</Box>
                            </Box>
                        }  {...a11yProps(0)} />
                        <Tab className="w-50" label={
                            <Box display="flex" alignItems="center" fontSize="12.5px" fontWeight="bolder" ml={1}>
                                <Box>Contributors({contributorsList.length})</Box>
                            </Box>
                        }  {...a11yProps(1)}/>
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <span  className="top-space-2"> <ConsumerDetails details={consumersList}/></span>
                    <a className="view-all-1" onClick={handleConsumerList} onKeyDown={handleConsumerList}>View All</a>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <UserRank />
                    <span className="top-space-2"> <ContributorDetails details={RankingContributors} user={loggedUser}/></span>
                    <a  className="view-all-1" onClick={handleContributorsList}  onKeyDown={handleContributorsList}>View All</a>
                </CustomTabPanel>
            </Box>
            {consumeDialog?<> <DialogConusmer onClick={handleConsumerList} details={consumersList} title="Consumers List"/></>:""}
            {contributeDialog?<DialogContribute onClick={handleContributorsList} details={RankingContributors}/>:""}
        </>
    );
}

export default TabView;